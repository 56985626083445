<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.tranchesTrancheHistory.title') + currentDialogTranche.number"
				max-width="568"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<div v-mutate="onIntersect" class="d-flex flex-column" style="row-gap: 16px">
				<div v-for="(item, i) in currentTrancheHistory" :key="i" class="d-flex flex-column">
					<span class="primary--text font-weight-bold" style="font-size: 14px">{{ $t(`aliases.trancheStatus.${item.newStatus}`) }}</span>
					<span class="grey--text" style="font-size: 12px">{{ item.author.name }}, {{ formatDate(item.changeAt, dateFormat) }}</span>
				</div>
			</div>
		</template>

		<template #footer>
			<frp-btn elevation="0" color="blue" dark
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "@/router/tranches/routes";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			history: state => state.history.trancheHistory
		}),
		...mapGetters({
			currentDialogTranche: getterTypes.currentDialogTranche
		}),
		currentTrancheHistory() {
			return this.history.filter(x => x.trancheNumber === this.currentDialogTranche.number);
		}
	},
	methods: {
		...mapMutations({
			setCurrentDialogTrancheNumber: mutationTypes.SET_CURRENT_DIALOG_TRANCHE_NUMBER
		}),
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		},
		handleClose() {
			this.setCurrentDialogTrancheNumber(undefined);
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
