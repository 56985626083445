import { ApiTrancheScheduleTranche } from "@/api/tranches/types/apiTrancheScheduleTranche";
import { convertToTimestamp, convertToZonedTimestamp, formatDate } from "@/utils/dates";
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import { ApiTrancheScheduleDocument } from "@/api/tranches/types/apiTrancheScheduleDocument";
import { i18n } from "@/plugins";
import { dateFormat } from "@/utils/formats";

export interface TrancheScheduleTranche {
	index: number;
	number: number;
	amount: number;
	plannedDate: number;
	orderDate: number | null;
	orderCreating: boolean;
	status: ApiTrancheStatusTypeEnum;
	documents: ApiTrancheScheduleDocument[];
	linkedCounterpartyAgreements: number[];
	title: string;
}

export class TrancheScheduleTrancheHelper {
	static map(source: ApiTrancheScheduleTranche, index: number): TrancheScheduleTranche {
		return {
			...source,
			plannedDate: convertToZonedTimestamp(source.plannedDate) as number,
			orderDate: source.orderDate ? convertToZonedTimestamp(source.orderDate) as number : null,
			orderCreating: false,
			index,
			title: i18n.t("common.trancheByDate",
				{ number: source.number, date: formatDate(convertToZonedTimestamp(source.plannedDate) as number, dateFormat) }) as string
		};
	}
	
	static getEmpty(): TrancheScheduleTranche {
		return {
			index: 0,
			number: 0,
			amount: 0,
			plannedDate: 0,
			orderDate: 0,
			orderCreating: false,
			status: ApiTrancheStatusTypeEnum.DRAFT,
			documents: [],
			linkedCounterpartyAgreements: [],
			title: ""
		};
	}
}