<template>
	<tranches-content-layout>
		<v-row>
			<v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7">
				<v-row class="d-flex flex-column">
					<v-col>
						<tranches-tranche-application-project-info-loader></tranches-tranche-application-project-info-loader>
					</v-col>
					
					<v-col>
						<tranches-tranche-application-tranche-schedule-loader></tranches-tranche-application-tranche-schedule-loader>
					</v-col>
					
					<v-col v-if="can(Permissions.TRANCHES_TRANCHE_SCHEDULE_MANAGE_FILES)">
						<tranches-tranche-application-generation-loader></tranches-tranche-application-generation-loader>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</tranches-content-layout>
</template>

<script>
import TranchesContentLayout from "@/components/layouts/TranchesContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import TranchesTrancheApplicationGenerationLoader
	from "@/views/tranches/sections/generation/TranchesTrancheApplicationGenerationLoader.vue";
import TranchesTrancheApplicationProjectInfoLoader from "@/views/tranches/sections/projectInfo/TranchesTrancheApplicationProjectInfoLoader.vue";
import TranchesTrancheApplicationTrancheScheduleLoader
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheScheduleLoader.vue";

export default {
	mixins: [authorizationMixin],
	components: {
		TranchesTrancheApplicationGenerationLoader,
		TranchesTrancheApplicationTrancheScheduleLoader,
		TranchesContentLayout,
		TranchesTrancheApplicationProjectInfoLoader,
	}
};
</script>


<style scoped>

</style>