import IPageState from "@/store/shared/base/types/pageState";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { ProjectInfo, ProjectInfoHelper } from "@/store/tranches/modules/application/types/projectInfo";
import { TrancheSchedule, TrancheScheduleHelper } from "@/store/tranches/modules/application/types/trancheSchedule";
import { TrancheScheduleTranche } from "@/store/tranches/modules/application/types/trancheScheduleTranche";
import { TrancheScheduleHistory } from "@/types/tranches/trancheScheduleHistory";

export default class TranchesTrancheApplicationState implements IPageState {
	constructor(
		public listing: ListingModel<TrancheScheduleTranche>,
		public sorting: SortingModel<String>,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isTrancheScheduleSaving: boolean = false,
		public isTrancheScheduleOrderConditionsSaving: boolean = false,
		public isTrancheScheduleFormValid: boolean = false,
		public isTrancheScheduleForApprovalByManagerSending: boolean = false,
		public isOrderTranchePaymentApplicationCreating: boolean = false,
		public applicationNumber: string = "",
		public isProjectInfoFetching: boolean = false,
		public projectInfo: ProjectInfo = ProjectInfoHelper.getEmpty(),
		public trancheSchedule: TrancheSchedule = TrancheScheduleHelper.getEmpty(),
		public isTrancheAccepting: boolean = false,
		public isTrancheRejecting: boolean = false,
		public isTrancheConfirming: boolean = false,
		public isDocumentUploading: boolean = false,
		public isTrancheAgreementsSaving: boolean = false,
		public isUnaccepting: boolean = false,
		public deletingDocumentIds: string[] = [],
		public mode: TrancheScheduleModeTypeEnum = TrancheScheduleModeTypeEnum.READ,
		public editableItems: TrancheScheduleTranche[] = [],
		public trancheScheduleSum: number = 0,
		public currentDialogTrancheNumber: number | undefined = undefined,
		public isTrancheScheduleUnsavedChangesDialogEnabled: boolean = false,
		public history: TrancheScheduleHistory = {} as TrancheScheduleHistory,
		public closestConfirmedTrancheNumber?: number,
		public closestDraftTrancheNumber?: number,
		public trancheOrderFile?: Blob,
		public trancheOrderFilename?: string
	)
	{
	}
}